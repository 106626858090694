<template>
  <div class="survey-finish-container">
    <div class="img-cont">
      <img src="@/assets/images/cake.png" alt="image of a cake">
    </div>
    <h2 class="tw-text-2xl">
      {{ surveyFinishDetails.title }}
    </h2>
    <p v-if="surveyFinishDetails.details">
      {{ surveyFinishDetails.details }}
    </p>
    <el-button v-if="surveyFinishDetails.redirectButtonUrl" class="next-button signin-button" @click.prevent="redirectToSsoUrl">
      {{ surveyFinishDetails.redirectButtonText }}
    </el-button>
  </div>
</template>
<script>
export default {
  name: 'SurveyFinish',
  data() {
    return {};
  },
  computed: {
    surveyFinishDetails() {
      return this.$store.getters.surveyFinishDetails[this.completionStatus];
    },
    completionStatus() {
      return this.$store.getters.user.status;
    },
    user() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    redirectToSsoUrl() {
      this.$router.push(this.surveyFinishDetails.redirectButtonUrl);
    }
  }
};
</script>

<style lang="scss" scoped>

.signin-button {
  width: 50%;
}
</style>
